import { isNullOrUndefined } from "util";
import { RootState } from "store/reducers";
import { createSelector } from "@reduxjs/toolkit";

const selectAuthState = (state: RootState) => state.auth;

export const selectIsAuthenticating = createSelector(
  selectAuthState,
  (state) => state.isAuthenticating
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state) => !isNullOrUndefined(state.user)
);
