import React from "react";
import "./LoadingOverlay.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface LoadingOverlayProps {
  text?: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  text,
}: LoadingOverlayProps) => (
  <div className="loading-overlay">
    <Spin indicator={antIcon} />
    {text ? <span className="loading-text">{text}</span> : null}
  </div>
);

export default LoadingOverlay;
