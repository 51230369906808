import { useState, useEffect } from "react";

type PlotDataNormalizer<T, X, Y> = (input: T) => { x: X; y: Y };

/**
 * Transform any array inputs into an array which can be used for rendering
 * by Victory Chart.
 *
 * @param plotDataInput
 *      Data source for plotting. An array of any types would be accepted.
 * @param plotDataNormalizer
 *     A function which takes an entry from the first argument, and transforms
 *     it to a point renderable on-screen.
 *
 * @returns
 *     An array of objects containing properties `x`, and `y`.
 *     This array can be used as data inputs to Victory Charts.
 */
const useVictoryPlotData = <T, X = unknown, Y = unknown>(
  plotDataInput: T[],
  plotDataNormalizer: PlotDataNormalizer<T, X, Y>
) => {
  const [plotData, setPlotData] = useState(
    plotDataInput.map(plotDataNormalizer)
  );

  useEffect(() => {
    setPlotData(plotDataInput.map(plotDataNormalizer));
  }, [plotDataInput, plotDataNormalizer]);

  return plotData;
};

export default useVictoryPlotData;
