import { useEffect } from "react";

/**
 * Accepts a function which will be invoked once and once only, after a React
 * functional component is mounted successfully.
 *
 * Semantically, this is intended as a replacement of the `componentDidMount`
 * lifecycle hook:
 *   https://reactjs.org/docs/react-component.html#componentdidmount
 *
 * @param onInitEffect
 *     A callback function which will be invoked when the component mounts.
 *     Note that this function will be executed at most once.
 */
export const useOnInit = (onInitEffect: Function) => {
  useEffect(() => {
    onInitEffect();
    // The dependency array can be safely ignored, since the callback is only
    // expected to be called once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
