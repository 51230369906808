import { combineReducers } from "redux";
import {
  reducer as authReducer,
  initialState as authInitialState,
} from "./auth/auth.slice";

export const rootReducer = combineReducers({ auth: authReducer });

export const rootInitialState = {
  auth: authInitialState,
};

export type RootState = ReturnType<typeof rootReducer>;
