/* eslint-disable @typescript-eslint/camelcase */
import { Auth0ClientOptions } from "@auth0/auth0-spa-js";

if (
  !process.env.REACT_APP_AUTH0_DOMAIN ||
  !process.env.REACT_APP_AUTH0_CLIENT_ID
) {
  throw new Error("Auth0 environment has not been configured.");
}

const validatedDomain = process.env.REACT_APP_AUTH0_DOMAIN;
const validatedRedirectUri = process.env.REACT_APP_AUTH0_CLIENT_ID;

export default {
  domain: validatedDomain,
  client_id: validatedRedirectUri,
  redirect_uri: window.location.origin,
} as Auth0ClientOptions;
