import { useEffect, useState } from "react";

/**
 * Debounce any value that are fast-changing.
 *
 * See the diagram below for a visualization of useDebounce.
 *
 * ```
 * ORIGINAL       |-x---x--x---x----x---x-x-x--x|
 * DEBOUNCED(10)  |-x----------x----------x-----|
 * ```
 *
 * @param value
 *     The value (presumably fast-changing) to debounce for.
 * @param delay
 *     The delay duration, in miliseconds. Defaults to 500 miliseconds.
 *     Changes in `value` emitted during this this delay period will be ignored.
 * @returns
 *     The debounced value, which changes at most once within the `delay` period.
 */
export const useDebounce = <T>(value: T, delay = 500) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};
