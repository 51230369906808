import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { rootReducer, RootState } from "./reducers";

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});
