import React, { useState } from "react";
import ReactGA from "react-ga";
import { useOnInit } from "../hooks";

const GA_TRACKING_ID = "UA-177867567-1";

/**
 * Initializes Google Analytics services.
 *
 * This function must be invoked before any analytics are to be recorded.
 * If this function is not called, analytics will not be forwared to GA.
 */
const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

interface GAContextProperties {
  enabled: boolean;
}

const initialGAProperties: GAContextProperties = {
  enabled: process.env.NODE_ENV === "production",
};

const GAContext = React.createContext(initialGAProperties);

const GAProvider: React.FC = ({ children }) => {
  const [state] = useState(initialGAProperties);

  useOnInit(() => {
    if (state.enabled) {
      initGA();
    }
  });

  return <GAContext.Provider value={state}>{children}</GAContext.Provider>;
};

export const useGAConfig = () => {
  const gaContext = React.useContext(GAContext);
  if (gaContext === undefined) {
    throw new Error("useGAConfig must be used within a `GAProvider`.");
  }
  return gaContext;
};

export default GAProvider;
