import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import { AuthService } from "services";
import { MFGUser } from "../../models/user.model";

export interface AuthState {
  user: MFGUser | undefined;
  isAuthenticating: boolean;
  error: unknown;
}

export const initialState: AuthState = {
  user: undefined,
  isAuthenticating: false,
  error: undefined,
};

interface LoginSuccessPayload {
  user: MFGUser;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequested: (state) => ({ ...state, isAuthenticating: true }),
    loginSuccess: (state, { payload }: PayloadAction<LoginSuccessPayload>) => ({
      ...state,
      isAuthenticating: false,
      user: payload.user,
    }),
    loginFailure: (state, { payload }: PayloadAction<{ error: unknown }>) => ({
      ...state,
      isAuthenticating: false,
      error: payload.error,
    }),
    logoutRequested: (state) => ({ ...state, user: undefined }),
  },
});

export const {
  loginRequested,
  loginSuccess,
  loginFailure,
  logoutRequested,
} = authSlice.actions;

export const { reducer } = authSlice;

export const login = (): AppThunk => async (dispatch) => {
  dispatch(loginRequested());
  AuthService.loginWithRedirect();
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(logoutRequested());
  await AuthService.logout({ returnTo: window.location.origin });
};

export const recoverAuthState = (): AppThunk => async (dispatch) => {
  dispatch(loginRequested());

  try {
    const shouldHandleLoginRedirect = window.location.search.includes("code=");

    const authResult = shouldHandleLoginRedirect
      ? await AuthService.handleRedirectCallback()
      : await AuthService.isAuthenticated();

    if (!authResult) {
      dispatch(loginFailure({ error: "Authentication failed" }));
      return;
    }

    dispatch(loginSuccess({ user: await AuthService.getUser() }));
  } catch (error) {
    dispatch(loginFailure({ error }));
  }
};
