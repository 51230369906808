import { useGAConfig } from "contexts/GoogleAnalytics";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

/**
 * Records the app's pageview analytics through Google Analytics.
 *
 * Pageview analytics refers to tracking which 'relative path' our users are
 * visiting. Relative paths such as '/home', '/opcalc', etc. will be forwarded
 * to Google Analytics.
 *
 * ### implicit dependencies
 *
 * - This hook depends on `react-router`. It should be invoked only after the
 *     `react-router` context has been made available.
 * - This hook assumes GA has been initialized. See function `initGA` for more
 *     context.
 */
export const usePageviewAnalytics = () => {
  const { enabled: isGoogleAnalyticsEnabled } = useGAConfig();
  const location = useLocation();

  useEffect(() => {
    if (!isGoogleAnalyticsEnabled) return;

    const pageviewLoc = location.pathname + location.search;
    ReactGA.pageview(pageviewLoc);
  }, [location, isGoogleAnalyticsEnabled]);
};
