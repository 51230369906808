import { useState, useEffect } from "react";

/**
 * Returns a boolean, indicating whether the specified delay has been reached
 * since the component mounted.
 *
 * @param delayMS The delay duration, in miliseconds.
 */
export const useDelay = (delayMS: number) => {
  const [delayReached, setDelayReached] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayReached(true);
    }, delayMS);
    return () => clearTimeout(timer);
  }, [delayMS]);

  return delayReached;
};
