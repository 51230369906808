import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "themes/default-theme";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import GAProvider from "./contexts/GoogleAnalytics";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const client = new ApolloClient({
  uri: "/.netlify/functions/graphql",
  cache: new InMemoryCache(),
});

const AppWithProviders = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <GAProvider>
            <App />
         </GAProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>
);

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(<AppWithProviders />, rootElement);
} else {
  render(<AppWithProviders />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
