/* eslint-disable @typescript-eslint/camelcase */
import createAuth0Client, {
  Auth0Client,
  RedirectLoginResult,
  RedirectLoginOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";

import config from "auth0.config";

const initAuth0 = () =>
  createAuth0Client({
    domain: config.domain,
    client_id: config.client_id,
    redirect_uri: window.location.origin,
    // Persist authentication cache info in localstorage to maintain
    // user session across page refreshes.
    // This fix is required, as Safari's intelligent tracking prevention (ITP)
    // intercepts and stops Auth0's default user-session recovery method.
    // Note that using localstorage as the cache location comes with additional
    // security risks, but this would likely be acceptable for our use cases.
    cacheLocation: "localstorage",
  });

type RedirectCallback = (result: RedirectLoginResult) => void;

const DEFAULT_REDIRECT_CALLBACK: RedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

class AuthService {
  private auth0Client: Auth0Client | undefined;

  async isAuthenticated() {
    await this.initAuth0ClientOnDemand();

    return this.auth0Client?.isAuthenticated();
  }

  async handleRedirectCallback(
    onRedirectCallback: RedirectCallback = DEFAULT_REDIRECT_CALLBACK
  ) {
    await this.initAuth0ClientOnDemand();

    const res = await this.auth0Client?.handleRedirectCallback();
    if (!res) return;
    const { appState } = res;
    onRedirectCallback(appState);
    return this.auth0Client?.isAuthenticated();
  }

  async getUser() {
    await this.initAuth0ClientOnDemand();

    if (!(await this.auth0Client?.isAuthenticated())) {
      return;
    }
    return this.auth0Client?.getUser();
  }

  async loginWithRedirect(options?: RedirectLoginOptions) {
    await this.initAuth0ClientOnDemand();

    return this.auth0Client?.loginWithRedirect(options);
  }

  async logout(options?: LogoutOptions) {
    await this.initAuth0ClientOnDemand();

    return this.auth0Client?.logout(options);
  }

  private async initAuth0ClientOnDemand() {
    if (!this.auth0Client) {
      this.auth0Client = await initAuth0();
    }
  }
}

const authService = new AuthService();

export default authService;
